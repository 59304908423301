<i18n>
ru:
  btnText: 'В корзину за '
  btnTextCount: '{num} шт х '
ua:
  btnText: 'До кошику за '
  btnTextCount: '{num} шт х '
us:
  btnText: 'Add to cart for '
  btnTextCount: '{num} item(s) х '
</i18n>

<template>
  <arora-stepper
    v-if="
      appConfig.VueSettingsPreRun.MenuItemInCartBehavior === 'arithmetic' &&
      productsInCart.length > 0
    "
    large
    :disable-plus-button="
      !isEnoughPointsToBuy ||
      (productInList.MaxInCart > 0 &&
        productsInCart[0].Count >= productInList.MaxInCart)
    "
    :data-test-id="`add-to-cart-button-open-${productsInCart[0].ID}`"
    :disabled="productsInCart[0].FixedCount || disabled"
    :label="translate('addToCartButton.addToCart')"
    :on-decrement="() => setItemCountLocal(false)"
    :on-increment="() => setItemCountLocal(true)"
    :value="priceInPoints > 0 ? priceInPoints : price"
  >
    <div class="v-align-currency-center">
      {{
        translate('addToCartButtonPage.btnTextCount', { num: productInCartCount })
      }}
      <common-currency
        v-if="priceInPoints > 0"
        show-points
        :amount="priceInPoints"
      />
      <common-currency
        v-else
        :amount="price"
      />
    </div>
  </arora-stepper>
  <arora-button
    v-else-if="productInList"
    :class-name="`
      v-btn-lg v-btn-block v-align-currency-center ${
        priceInPoints > 0 && !isEnoughPointsToBuy
          ? 'v-btn-gray v-pointer-events-none'
          : ''
      }
    `"
    :disabled="disabled"
    :label="translate('addToCartButton.addToCart')"
    data-test-id="add-to-cart-button-open"
    @click="async () => await buttonClickLocal()"
  >
    <template v-if="priceInPoints > 0">
      <template v-if="isEnoughPointsToBuy">
        <common-currency
          show-points
          :amount="priceInPoints"
        />
      </template>
      <template v-else>
        <span
          class="v-mr-xs"
          v-html="translate('addToCartButton.more')"
        />
        <common-currency
          show-points
          :amount="priceInPoints - accountStore.PointsBalance"
        />
      </template>
    </template>
    <template v-else>
      <span v-html="translate('addToCartButtonPage.btnText')" />
      <common-currency :amount="price" />
    </template>
  </arora-button>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'
import type { ProductInList } from '~types/menuStore'

import type { CanBeDisabled, GUID } from '@arora/common'

const {
  buttonClick,
  priceInPoints = 0,
  productInList
} = defineProps<
  CanBeDisabled & {
    productInList: ProductInList
    buttonClick: (id: GUID) => Promise<void>
    price: number
    priceInPoints?: number
  }
>()

const menuStore = useMenuStore()
const accountStore = useAccountStore()

const { deleteItem } = useCart()

const isEnoughPointsToBuy = computed<boolean>(() => {
  if (productInList.PriceInPoints === 0 || !accountStore.isLoggedIn) return true

  return (
    (clientStore?.ClientState?.data?.Cart?.ToPayWithPoints?.Amount ?? 0) +
      productInList.PriceInPoints +
      (menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.PriceInPoints ??
        0) <
    accountStore.PointsBalance
  )
})

const appConfig = useAppConfig()
const clientStore = useClientStore()

const { translate } = useI18nSanitized()
const { setItemCount } = useCartFunctionsWithDialogs()
const { getSameProductsInCart, setProductModifiersToZero } = useProduct()

const productsInCart = computed<CartItem[]>(() =>
  getSameProductsInCart(productInList.ID, true)
)

const productInCartCount = computed<number>(() => {
  return productsInCart.value.reduce(
    (partialSum, productInCartSingle) => partialSum + productInCartSingle.Count,
    0
  )
})

async function setItemCountLocal(increment: boolean): Promise<void> {
  if (productsInCart.value.length === 0) return

  const firstProductInCart = productsInCart.value[0]

  if (increment) {
    await setItemCount(
      firstProductInCart.ID,
      firstProductInCart.RealCount + 1,
      firstProductInCart.GroupID,
      firstProductInCart.Name
    )
  } else if (firstProductInCart.RealCount === 1) {
    await deleteItem(firstProductInCart, false)
    setProductModifiersToZero(productInList.ID)
  } else {
    await setItemCount(
      firstProductInCart.ID,
      firstProductInCart.RealCount - 1,
      firstProductInCart.GroupID,
      firstProductInCart.Name
    )
  }
}

async function buttonClickLocal(): Promise<void> {
  if (productsInCart.value.length > 0) {
    await setItemCountLocal(true)
  } else {
    await buttonClick(productInList.ID)
    setProductModifiersToZero(productInList.ID)
  }
}
</script>
